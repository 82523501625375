import { useEffect, useState } from 'react';
import { httpGet } from '../../lib/http.ts';
import { DefaultSpinner } from '../Loaders/DefaultSpinner.tsx';
import { createTokenCookie } from '../../lib/jwt.ts';
import { redirectAuthSuccess } from '../../lib/auth-redirect.ts';

type GitHubButtonProps = {};

export function GitHubButton(props: GitHubButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const provider = urlParams.get('provider');

    if (!code || !state || provider !== 'github') {
      return;
    }

    setIsLoading(true);
    httpGet<{ token: string }>(
      `${import.meta.env.PUBLIC_API_URL}/v1-github-callback${
        window.location.search
      }`,
    )
      .then(({ response, error }) => {
        if (!response?.token) {
          const errMessage = error?.message || 'Something went wrong.';
          setError(errMessage);
          setIsLoading(false);

          return;
        }

        createTokenCookie(response.token);
        redirectAuthSuccess();
      })
      .catch(() => {
        setError('Something went wrong. Please try again later.');
        setIsLoading(false);
      });
  }, []);

  const handleClick = async () => {
    setIsLoading(true);

    const { response, error } = await httpGet<{ loginUrl: string }>(
      `${import.meta.env.PUBLIC_API_URL}/v1-github-login`,
    );

    if (error || !response?.loginUrl) {
      setError(
        error?.message || 'Something went wrong. Please try again later.',
      );

      setIsLoading(false);
      return;
    }

    window.location.href = response.loginUrl;
  };

  return (
    <>
      <button
        className="inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"
        disabled={isLoading}
        onClick={handleClick}
      >
        {!isLoading && (
          <>
            <img
              src={'/icons/github.svg'}
              alt="GitHub"
              className={`h-[18px] w-[18px]`}
            />
            Continue with GitHub
          </>
        )}
        {isLoading && <DefaultSpinner />}
      </button>
      {error && (
        <p className="mb-2 mt-1 text-sm font-medium text-red-600">{error}</p>
      )}
    </>
  );
}
